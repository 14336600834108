// src/actions/actionTypes.js
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const FETCH_PAYMENT_OPTIONS_REQUEST = 'FETCH_PAYMENT_OPTIONS_REQUEST';
export const FETCH_PAYMENT_OPTIONS_SUCCESS = 'FETCH_PAYMENT_OPTIONS_SUCCESS';
export const FETCH_PAYMENT_OPTIONS_FAILURE = 'FETCH_PAYMENT_OPTIONS_FAILURE';

export const FETCH_ALL_USERS_REQUESTS_REQUEST = 'FETCH_ALL_USERS_REQUESTS_REQUEST';
export const FETCH_ALL_USERS_REQUESTS_SUCCESS = 'FETCH_ALL_USERS_REQUESTS_SUCCESS';
export const FETCH_ALL_USERS_REQUESTS_FAILURE = 'FETCH_ALL_USERS_REQUESTS_FAILURE';


export const FETCH_ALL_PAYMENT_REQUESTS_REQUEST = 'FETCH_ALL_PAYMENT_REQUESTS_REQUEST';
export const FETCH_ALL_PAYMENT_REQUESTS_SUCCESS = 'FETCH_ALL_PAYMENT_REQUESTS_SUCCESS';
export const FETCH_ALL_PAYMENT_REQUESTS_FAILURE = 'FETCH_ALL_PAYMENT_REQUESTS_FAILURE';


export const FETCH_ALL_ORDER_REQUEST = 'FETCH_ALL_ORDER_REQUEST';
export const FETCH_ALL_ORDER_SUCCESS = 'FETCH_ALL_ORDER_SUCCESS';
export const FETCH_ALL_ORDER_FAILURE = 'FETCH_ALL_ORDER_FAILURE';

export const FETCH_ALL_SENDER_REQUEST = 'FETCH_ALL_SENDER_REQUEST';
export const FETCH_ALL_SENDER_SUCCESS = 'FETCH_ALL_SENDER_SUCCESS';
export const FETCH_ALL_SENDER_FAILURE = 'FETCH_ALL_SENDER_FAILURE';

export const FETCH_ALL_RECEIVER_REQUEST = 'FETCH_ALL_RECEIVER_REQUEST';
export const FETCH_ALL_RECEIVER_SUCCESS = 'FETCH_ALL_RECEIVER_SUCCESS';
export const FETCH_ALL_RECEIVER_FAILURE = 'FETCH_ALL_RECEIVER_FAILURE';

export const FETCH_ALL_REWARD_REQUEST = 'FETCH_ALL_REWARD_REQUEST';
export const FETCH_ALL_REWARD_SUCCESS = 'FETCH_ALL_REWARD_SUCCESS';
export const FETCH_ALL_REWARD_FAILURE = 'FETCH_ALL_REWARD_FAILURE';

export const FETCH_ALL_LEVEL_REQUEST = 'FETCH_ALL_LEVEL_REQUEST';
export const FETCH_ALL_LEVEL_SUCCESS = 'FETCH_ALL_LEVEL_SUCCESS';
export const FETCH_ALL_LEVEL_FAILURE = 'FETCH_ALL_LEVEL_FAILURE';

export const APPROVE_REWARD_REQUEST_REQUEST = 'APPROVE_REWARD_REQUEST_REQUEST';
export const APPROVE_REWARD_REQUEST_SUCCESS = 'APPROVE_REWARD_REQUEST_SUCCESS';
export const APPROVE_REWARD_REQUEST_FAILURE = 'APPROVE_REWARD_REQUEST_FAILURE';

export const ASSIGN_HELP_REQUEST_REQUEST = 'ASSIGN_HELP_REQUEST_REQUEST';
export const ASSIGN_HELP_REQUEST_SUCCESS = 'ASSIGN_HELP_REQUEST_SUCCESS';
export const ASSIGN_HELP_REQUEST_FAILURE = 'ASSIGN_HELP_REQUEST_FAILURE';

export const FETCH_ASSIGNED_HELP_REQUEST = 'FETCH_ASSIGNED_HELP_REQUEST';
export const FETCH_ASSIGNED_HELP_SUCCESS = 'FETCH_ASSIGNED_HELP_SUCCESS';
export const FETCH_ASSIGNED_HELP_FAILURE = 'FETCH_ASSIGNED_HELP_FAILURE';

export const REJECT_REWARD_REQUEST_REQUEST = 'REJECT_REWARD_REQUEST_REQUEST';
export const REJECT_REWARD_REQUEST_SUCCESS = 'REJECT_REWARD_REQUEST_SUCCESS';
export const REJECT_REWARD_REQUEST_FAILURE = 'REJECT_REWARD_REQUEST_FAILURE';

export const APPROVE_PAYMENT_REQUEST_REQUEST = 'APPROVE_PAYMENT_REQUEST_REQUEST';
export const APPROVE_PAYMENT_REQUEST_SUCCESS = 'APPROVE_PAYMENT_REQUEST_SUCCESS';
export const APPROVE_PAYMENT_REQUEST_FAILURE = 'APPROVE_PAYMENT_REQUEST_FAILURE';

export const REJECT_PAYMENT_REQUEST_REQUEST = 'REJECT_PAYMENT_REQUEST_REQUEST';
export const REJECT_PAYMENT_REQUEST_SUCCESS = 'REJECT_PAYMENT_REQUEST_SUCCESS';
export const REJECT_PAYMENT_REQUEST_FAILURE = 'REJECT_PAYMENT_REQUEST_FAILURE';

export const FETCH_DASHBOARD_DATA_REQUEST = 'FETCH_DASHBOARD_DATA_REQUEST';
export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const FETCH_DASHBOARD_DATA_FAILURE = 'FETCH_DASHBOARD_DATA_FAILURE';

export const FETCH_PAYMENT_TRANSACTIONS_REQUEST = 'FETCH_PAYMENT_TRANSACTIONS_REQUEST';
export const FETCH_PAYMENT_TRANSACTIONS_SUCCESS = 'FETCH_PAYMENT_TRANSACTIONS_SUCCESS';
export const FETCH_PAYMENT_TRANSACTIONS_FAILURE = 'FETCH_PAYMENT_TRANSACTIONS_FAILURE';
