import React from "react";
import { Clipboard } from "lucide-react";
import "./CopyComponent.css";
import { toast, ToastContainer } from "react-toastify";

const CopyComponent = ({ value }) => {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    toast.success("Copied to Clipboard!");
  };

  return (
    <div>
      <button onClick={handleCopy} className="copyDiv">
        <Clipboard size={16} />
      </button>
    </div>
  );
};

export default CopyComponent;
