import React, { useEffect, useState, useRef } from "react";
import "./Sidebar.css";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillHome } from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";
import { BsFillCartCheckFill } from "react-icons/bs";
import { IoIosLogOut } from "react-icons/io";
import { RiFundsBoxFill } from "react-icons/ri";
import { AiTwotoneFund } from "react-icons/ai";
import { RiRefund2Line } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { GiLetterBomb } from "react-icons/gi";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdSettings } from "react-icons/io";
import { FaHandsHelping } from "react-icons/fa";
import { FaMoneyBillAlt, FaMoneyCheckAlt } from "react-icons/fa";
import { RiAdvertisementFill } from "react-icons/ri";
import NavPages from "../NavPages";
import { useNavigate } from "react-router-dom";
import { BsHddStackFill } from "react-icons/bs";
import { PiRankingBold } from "react-icons/pi";
import Change from "../Common/StringToSub";
import useWindowDimensions from "../Common/useWindowDimensions";
import { useSelector, useDispatch } from "react-redux";
import { MyDelay } from "../Common/Data";
import { Data } from "../Common/Data";
import { IoQrCodeSharp } from "react-icons/io5";
import { BsBank2 } from "react-icons/bs";
import { FaGifts } from "react-icons/fa6";
import { BiMoneyWithdraw } from "react-icons/bi";
import { IoNewspaperOutline } from "react-icons/io5";
import { SiBnbchain } from "react-icons/si";
import { fetchDashboardData } from "../Actions/adminActions";
import { logout } from "../Actions/adminActions";
const Sidebar = () => {
  const { loading, error, user } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  useEffect(() => {
    if (error?.code === 419) {
      navigate("/", { replace: true });
    }
  }, [error, navigate]);

  const userAddress = localStorage.getItem("userAddress");
  const [openDropdown, setOpenDropdown] = useState(null);
  const { height, width } = useWindowDimensions();
  const hasFetchedData = useRef(false);
  const [toggle, setToggle] = useState("0");
  async function Logout() {
    await MyDelay(500);
    dispatch(logout()); // Dispatch the logout action
    navigate("/", { replace: true });
  }
  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown((prevState) =>
      prevState === dropdownName ? null : dropdownName
    );
  };

  useEffect(() => {
    if (width < 900) {
      setToggle("-260px");
    }
  }, [width]);

  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.admin);
  useEffect(() => {
    if (!hasFetchedData.current) {
      dispatch(fetchDashboardData());
      hasFetchedData.current = true;
    }
  }, []);

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <div id="sidebar-wrapper" className="" style={{ left: toggle }}>
          <div id="sidebarTopDiv">
            <div className="logo">
              <img
                src={Data.logoIc}
                alt="logo.png"
                height="auto"
                width="100px"
              />
            </div>
          </div>
          <section style={{ padding: "5px", marginTop: "80px" }}>
            <div className="logowithtext">
              {/* <img src={Data.logo} alt="logo.png" /> */}
              <h3 className="m-0">
                User Name <div></div>
              </h3>
              <p>{Change(userAddress)} </p>
            </div>
            <NavLink
              to=""
              end
              activeClassName="active"
              onClick={() => (width < 800 ? setToggle("-260px") : "")}
            >
              <div className="items">
                <i>
                  <AiFillHome />
                </i>
                <h4 className="m-0">Dashboard</h4>
              </div>
            </NavLink>
            <div
              className="dropdownitems"
              onClick={() => handleDropdownToggle("users")}
            >
              <div className={`head items`}>
                <i>
                  <IoIosPeople />
                </i>
                <h4 className="m-0">Users</h4>
                <i
                  className="dropicon"
                  style={{
                    transform: `rotate(${
                      openDropdown === "users" ? "180deg" : "0deg"
                    })`,
                  }}
                >
                  <BsChevronDown />
                </i>
              </div>
              {openDropdown === "users" && (
                <div className="dropdown">
                  <NavLink
                    to="all_users"
                    activeClassName="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (width < 800) {
                        setToggle("-260px");
                      }
                    }}
                  >
                    <div className="items">
                      <i>
                        <GiLetterBomb />
                      </i>
                      <h4 className="m-0">All Users</h4>
                    </div>
                  </NavLink>
                  <NavLink
                    to="rank"
                    activeClassName="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (width < 800) {
                        setToggle("-260px");
                      }
                    }}
                  >
                    <div className="items">
                      <i>
                        <FaGifts />
                      </i>
                      <h4 className="m-0">Rewards</h4>
                    </div>
                  </NavLink>
                  {/* <NavLink
                    to="level_ranks"
                    activeClassName="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (width < 800) {
                        setToggle("-260px");
                      }
                    }}
                  >
                    <div className="items">
                      <i>
                        <PiRankingBold />
                      </i>
                      <h4 className="m-0">Level Ranks</h4>
                    </div>
                  </NavLink> */}
                </div>
              )}
            </div>

            {/* <div
              className="dropdownitems"
              onClick={() => handleDropdownToggle("funds")}
            >
              <div className={`head items`}>
                <i>
                  <AiOutlineFundProjectionScreen />
                </i>
                <h4 className="m-0">Funds</h4>
                <div
                  className="reminder"
                  style={{
                    backgroundColor:
                      dashboardData?.countPendingPaymentRequests === 0
                        ? "green"
                        : "red",
                  }}
                >
                  {dashboardData?.countPendingPaymentRequests}
                </div>
                <i
                  className="dropicon"
                  style={{
                    transform: `rotate(${
                      openDropdown === "funds" ? "180deg" : "0deg"
                    })`,
                  }}
                >
                  <BsChevronDown />
                </i>
              </div>
              {openDropdown === "funds" && (
                <div className="dropdown">
                  <NavLink
                    to="fund_controls"
                    activeClassName="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (width < 800) {
                        setToggle("-260px");
                      }
                    }}
                  >
                    <div className="items">
                      <i>
                        <RiFundsBoxFill />
                      </i>
                      <h4 className="m-0">Fund Request</h4>
                    </div>
                  </NavLink>
                  <NavLink
                    to="add_fund"
                    activeClassName="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (width < 800) {
                        setToggle("-260px");
                      }
                    }}
                  >
                    <div className="items">
                      <i>
                        <RiRefund2Line />
                      </i>
                      <h4 className="m-0">Add Funds</h4>
                    </div>
                  </NavLink>
                  <NavLink
                    to="fund_history"
                    activeClassName="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (width < 800) {
                        setToggle("-260px");
                      }
                    }}
                  >
                    <div className="items">
                      <i>
                        <GiLetterBomb />
                      </i>
                      <h4 className="m-0">Funds History</h4>
                    </div>
                  </NavLink>
                </div>
              )}
            </div> */}

            <NavLink
              to="withdraw_controls"
              activeClassName="active"
              onClick={(e) => {
                e.stopPropagation();
                if (width < 800) {
                  setToggle("-260px");
                }
              }}
            >
              <div className="items">
                <i>
                  <BiMoneyWithdraw />
                </i>
                <h4 className="m-0"> Token Withdrawal</h4>
                <div
                  className="reminder"
                  style={{
                    backgroundColor:
                      dashboardData?.countPendingWithdrawals === 0
                        ? "green"
                        : "red",
                  }}
                >
                  {dashboardData?.countPendingWithdrawals}
                </div>
              </div>
            </NavLink>

            {/* <NavLink
              to="withdraw_controls_dollar"
              activeClassName="active"
              onClick={(e) => {
                e.stopPropagation();
                if (width < 800) {
                  setToggle("-260px");
                }
              }}
            >
              <div className="items">
                <i>
                  <BiMoneyWithdraw />
                </i>
                <h4 className="m-0">Withdrawal</h4>
                {/* <div
                  className="reminder"
                  style={{
                    backgroundColor:
                      dashboardData?.countPendingWithdrawals === 0
                        ? "green"
                        : "red",
                  }}
                >
                  {dashboardData?.countPendingWithdrawals}
                </div> */}
            {/* </div>
            </NavLink> */}

            {/* <NavLink
              to="senderReceiver_controls"
              activeClassName="active"
              onClick={() => (width < 800 ? setToggle("-260px") : "")}
            >
              <div className="items">
                <i>
                  <GrTransaction />
                </i>
                <h4 className="m-0">Sender Receiver</h4>
              </div>
            </NavLink> */}

            <NavLink
              to="payment_senderReceiver"
              activeClassName="active"
              onClick={() => (width < 800 ? setToggle("-260px") : "")}
            >
              <div className="items">
                <i>
                  <FaHandsHelping />
                </i>
                <h4 className="m-0">Assign Help</h4>
              </div>
            </NavLink>

            <NavLink
              to="payout_controls"
              activeClassName="active"
              onClick={() => (width < 800 ? setToggle("-260px") : "")}
            >
              <div className="items">
                <i>
                  <FaMoneyCheckAlt />
                </i>
                <h4 className="m-0">Payout Report</h4>
              </div>
            </NavLink>

            <NavLink
              to="orders"
              activeClassName="active"
              onClick={() => (width < 800 ? setToggle("-260px") : "")}
            >
              <div className="items">
                <i>
                  <BsFillCartCheckFill />
                </i>
                <h4 className="m-0">Orders</h4>
              </div>
            </NavLink>

            <NavLink
              to="advertisement"
              activeClassName="active"
              onClick={(e) => {
                e.stopPropagation();
                if (width < 800) {
                  setToggle("-260px");
                }
              }}
            >
              <div className="items">
                <i>
                  <RiAdvertisementFill />
                </i>
                <h4 className="m-0">Advertisement</h4>
              </div>
            </NavLink>

            <NavLink
              to="news"
              activeClassName="active"
              onClick={(e) => {
                e.stopPropagation();
                if (width < 800) {
                  setToggle("-260px");
                }
              }}
            >
              <div className="items">
                <i>
                  <IoNewspaperOutline />
                </i>
                <h4 className="m-0">News</h4>
              </div>
            </NavLink>

            <div
              className="dropdownitems"
              onClick={() => handleDropdownToggle("settings")}
            >
              <div className={`head items`}>
                <i>
                  <IoMdSettings />
                </i>
                <h4 className="m-0">Settings</h4>
                <i
                  className="dropicon"
                  style={{
                    transform: `rotate(${
                      openDropdown === "settings" ? "180deg" : "0deg"
                    })`,
                  }}
                >
                  <BsChevronDown />
                </i>
              </div>
              {openDropdown === "settings" && (
                <div className="dropdown">
                  <NavLink
                    to="chain"
                    activeClassName="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (width < 800) {
                        setToggle("-260px");
                      }
                    }}
                  >
                    <div className="items">
                      <i>
                        <SiBnbchain />
                      </i>
                      <h4 className="m-0">Web3 Setting</h4>
                    </div>
                  </NavLink>
                  {/* <NavLink
                    to="plan_setting"
                    activeClassName="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (width < 800) {
                        setToggle("-260px");
                      }
                    }}
                  >
                    <div className="items">
                      <i>
                        <BsHddStackFill />
                      </i>
                      <h4 className="m-0">Plan Setting</h4>
                    </div>
                  </NavLink>
                  <NavLink
                    to="bonanza_setting"
                    activeClassName="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (width < 800) {
                        setToggle("-260px");
                      }
                    }}
                  >
                    <div className="items">
                      <i>
                        <BsHddStackFill />
                      </i>
                      <h4 className="m-0">Bonanza Setting</h4>
                    </div>
                  </NavLink> */}
                </div>
              )}
            </div>

            <div className="items" onClick={Logout}>
              <i>
                <IoIosLogOut />
              </i>
              <h4 className="m-0">Logout</h4>
            </div>
          </section>
        </div>
        <div
          id="page-content"
          className="p-0"
          style={{
            background: "#F4F7FC",
            minHeight: "100vh",
            marginLeft:
              width > 900 ? (toggle === "-260px" ? "0px" : "260px") : "0px",
          }}
        >
          <div className="navMain">
            <div className="topNavbar">
              <i
                onClick={() =>
                  toggle === "-260px" ? setToggle("0") : setToggle("-260px")
                }
                style={{ cursor: "pointer" }}
              >
                <GiHamburgerMenu />
              </i>
              <div
              // style={{ marginRight: toggle === "-260px" ? "0px" : "260px" }}
              ></div>
            </div>
          </div>

          <div style={{ margin: "80px 5px" }}>{<NavPages />}</div>
        </div>
      </Container>
    </React.Fragment>
  );
};
// const Sidebar = withRouter(Side);
export default Sidebar;
